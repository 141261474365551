<template>
  <span class="icon-angle-right-3">
    <fa-icon icon="angle-right" v-bind="$attrs" />
    <fa-icon icon="angle-right" v-bind="$attrs" />
    <fa-icon icon="angle-right" v-bind="$attrs" />
  </span>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.icon-angle-right-3 {
  display: inline-block;
}
</style>