import { render, staticRenderFns } from "./MoIconAngleRight3.vue?vue&type=template&id=817c5880&scoped=true&"
import script from "./MoIconAngleRight3.vue?vue&type=script&lang=js&"
export * from "./MoIconAngleRight3.vue?vue&type=script&lang=js&"
import style0 from "./MoIconAngleRight3.vue?vue&type=style&index=0&id=817c5880&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817c5880",
  null
  
)

export default component.exports