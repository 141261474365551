import { render, staticRenderFns } from "./MoAccountConfirmEmailPage.vue?vue&type=template&id=75960122&scoped=true&"
import script from "./MoAccountConfirmEmailPage.vue?vue&type=script&lang=js&"
export * from "./MoAccountConfirmEmailPage.vue?vue&type=script&lang=js&"
import style0 from "./MoAccountConfirmEmailPage.vue?vue&type=style&index=0&id=75960122&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75960122",
  null
  
)

export default component.exports